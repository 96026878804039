@import url('https://fonts.googleapis.com/css?family=UnifrakturMaguntia');
@import url('https://fonts.googleapis.com/css?family=Poly');

.background {
    text-align: center;
}
.box {
  color: black;
}
.background h1 {
    margin-top: 0;
    font-family: UnifrakturMaguntia;
    font-weight: normal;
    font-size: 5vw;
    margin-bottom: 0px;
}
.background img {
    width: 80vw;
}
.container {
    display: flex;
    width: 80vw;
    font-family: poly;
    margin-left: 8.5vw;
    font-weight: bold;
  }
  .box {
    flex: 1;
    display: flex;
    justify-content: center;
    font-size: 1.25vw;
  }
  
  .box:first-child > span { margin-right: auto; }
  
  .box:last-child  > span { margin-left: auto;  }
  
  /* non-essential */
  .box {
    align-items: center;
  }