@import url('https://fonts.googleapis.com/css?family=Bitter');

.frame {
  height: 100vw;
}

.frame h1 {
  margin-top: 1vw;
  text-align: center;
  font-family: Bitter;
  font-size: 3vw;
}
.imagesfr {
  width: auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 2vw;
  justify-content: center;
  align-items: center;
}
 ul {
  padding:0; 
  margin: 0; 
}
.imagesfr > li {
  flex-basis: auto;
}
.imagesfr li img {
  height: 30vw;
  vertical-align: middle;
}
.frame h2 {
  margin-top: 3vw;
  text-align: center;
  font-family: Bitter;
  font-size: 3vw;
}
.frame p {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.667vw;
  font-family: Bitter;
  width: 60vw;
  letter-spacing: 0.067vw;
}
.flex-diversity{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-family: Bitter;
  font-size: 1.667vw;
  text-align: center;
  
}
.child {
  padding: 1vw;
}
.frame iframe {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 60vw;
  height: 40vw;
}